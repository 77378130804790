import React from "react";
import PropTypes from "prop-types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { WINDOW_SIZES } from "../../../constants/configuration";

import styles from "./NavBarComponent.scss";

const NavMenuItem = (props) => {
  // Anchor Props
  const hrefProp = {};

  const toggleSelection = (index, props) => {
    props.setExpandedItem(index !== props.expanededItem ? index : -1);
  };

  // disabling anchor for menu item if the menu has sub items or dataAttributes
  if (!(props.navItem.subMenuItems || props.navItem.dataAttributes))
    hrefProp.href = props.navItem.url;

  // Anchor Classes
  const anchorClassNames = [];
  if (props.navItem.subMenuItems)
    anchorClassNames.push(styles["ut-navigation__list__item__submenu"]);
  if (props.index === props.expanededItem)
    anchorClassNames.push(styles["ut-navigation__list__item__submenu--active"]);

  return (
    <a
      {...hrefProp}
      className={anchorClassNames.join(" ")}
      onClick={() => {
        props.navItem.subMenuItems
          ? toggleSelection(props.index, props)
          : props.windowSize === WINDOW_SIZES.isSmall && props.toggleNavbar();
      }}
    >
      {props.navItem.icon && (
        <span className={styles["ut-navigation__list__item__icon"]}>
          <i className={`icon ${props.navItem.icon.replace(/\s/g, "")}`} />
        </span>
      )}
      {props.navItem.dataAttributes && (
        <span className={styles["ut-navigation__list__item__img"]}>
          <img src={props.navItem.dataAttributes["data-MarketImgUrl"]} />
        </span>
      )}
      <span className={styles["ut-navigation__list__item__text"]}>
        {props.navItem.text}
      </span>
      {props.navItem.subMenuItems && (
        <ExpandMoreIcon
          className={styles["ut-navigation__list__item__submenu__arrow"]}
        />
      )}
    </a>
  );
};
NavMenuItem.propTypes = {
  expanededItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  navItem: PropTypes.object,
  setExpandedItem: PropTypes.func,
  toggleNavbar: PropTypes.func,
};

export default NavMenuItem;
