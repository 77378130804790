import { connect } from "react-redux";

import {
  popMultiDrawerItem,
  resetMultiDrawer,
} from "../../actions/multiDrawerActions";
import MultiDrawer from "./MultiDrawerComponent";

const mapStateToProps = (state) => {
  return {
    drawers: state.multiDrawer.drawers,
    currentActivePage: state.root.rootAuth.currentActivePage,
    isAnonymousUser: state.app.isAnonymousUser,
    isAuthenticated: state.app.isAuthenticated,
    isMyUser: state.app.isMyUser,
    isPublicPage: state.app.isPublicPage,
    refreshDrawer: state.multiDrawer.refreshDrawer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popDrawer: () => {
      dispatch(popMultiDrawerItem());
    },
    resetMultiDrawer: () => {
      dispatch(resetMultiDrawer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiDrawer);
