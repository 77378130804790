import React from "react";
import styles from "./Notification.scss";
import { notificaitonType } from "../../../constants/notificationConstants";

const onNoticationClick = (props) => {
  props.canRemove && props.removeNotification(props.id);
};

const Notification = (props) => {
  const classes = [styles["ut-notification"]];
  switch (props.type) {
    case notificaitonType.default:
      classes.push(styles["ut-notification__default"]);
      if (!props.isOnline) {
        classes.push(styles["ut-notification__offline"]);
      }
      break;
    case notificaitonType.success:
      classes.push(styles["ut-notification__succees"]);
      break;
    case notificaitonType.warning:
      classes.push(styles["ut-notification__warning"]);
      break;
    case notificaitonType.error:
      classes.push(styles["ut-notification__error"]);
      break;
    case notificaitonType.info:
    default:
      classes.push(styles["ut-notification__info"]);
      break;
  }

  if (!props.isActive) classes.push(styles["ut-notification__inactive"]);

  return (
    <div className={styles["ut-notification__item"]}>
      <div
        id={`notification-${props.id}`}
        className={classes.join(" ")}
        onClick={() => onNoticationClick(props)}
      >
        {props.title && props.title.length && (
          <h4 className={styles["ut-notification__title"]}>{props.title}</h4>
        )}
        {props.message && props.message.length && (
          <p className={styles["ut-notification__message"]}>{props.message}</p>
        )}
      </div>
    </div>
  );
};

export default Notification;
