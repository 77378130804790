import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CustomScrollBar from "../CustomScrollBar";

import NavMenuItems from "./NavMenuItems";

import styles from "./NavBarComponent.scss";

const NavBar = (props) => {
  const { navBarList = [] } = props;

  useEffect(() => {
    if (props.loggedInUserId != null && !props.isAccountSuspended) {
      props.getNavigationList();
    }
    return () => {
      props.emptyNavbarState();
      props.abortNavigationRequest();
    };
  }, [props.loggedInUserId, props.isAccountSuspended]);

  const handleOutlineClick = (event, props) => {
    if (
      event.target ===
      document.getElementsByClassName(styles["ut-navigation__outline"])[0]
    )
      props.toggleNavbar();
  };

  const classNames = [styles["ut-navigation__outline"]];
  if (props.isNavBarOpen) classNames.push(styles["ut-navigation--active"]);

  //hiding navbar for iframe

  if (props.isHidden) classNames.push(styles["ut-navigation__outline--hide"]);

  return (
    <>
      {props.showNavBar ? (
        <div
          className={classNames.join(" ")}
          onClick={(e) => {
            handleOutlineClick(e, props);
          }}
        >
          <div className={styles["ut-navigation"]}>
            <CustomScrollBar autoHide>
              <NavMenuItems {...props} navBarList={navBarList} />
            </CustomScrollBar>
          </div>
        </div>
      ) : null}
    </>
  );
};

NavBar.propTypes = {
  expanededItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNavBarOpen: PropTypes.bool,
  navBarList: PropTypes.array,
  showNavBar: PropTypes.bool,
};

export default NavBar;
