import { connect } from "react-redux";

import LinearProgressBar from "./LinearProgressBarComponent";

const mapStateToProps = (state) => {
  return {
    isProgressBarLoading: state.appBar && state.appBar.isProgressBarLoading,
  };
};

export default connect(mapStateToProps, null)(LinearProgressBar);
