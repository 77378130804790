import React from "react";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";

import NavMenuItem from "./NavMenuItem";
import NavSubMenuItem from "./NavSubMenuItem";

import styles from "./NavBarComponent.scss";

const NavMenuItems = (props) => {
  let baseUrl = "";

  const navigationItems = orderBy(props.navBarList, "sortOrder", "asc");

  const isNavItemSelected = (navItem, props) => {
    const currentPath = props.location.pathname;
    const navItemUrl = navItem.url && navItem.url.toLowerCase().split("#");

    return (
      navItemUrl &&
      currentPath.toLowerCase().indexOf(navItemUrl[navItemUrl.length - 1]) === 0
    );
  };

  // Reset searchData when we change nav item MD-14269
  const resetSessionStorageSearchData = () => {
    sessionStorage.setItem("searchData", JSON.stringify({}));
  };

  const navigationMenuContent = navigationItems.map((navItem, index) => {
    // Navigation Item
    const navigationItem = (
      <NavMenuItem
        expanededItem={props.expanededItem}
        index={index}
        navItem={navItem}
        setExpandedItem={props.setExpandedItem}
        toggleNavbar={props.toggleNavbar}
        windowSize={props.windowSize}
      />
    );

    const liClassNames = [];
    liClassNames.push(styles["ut-navigation__list__item"]);

    // check if current pathname exist in navItem.url
    if (isNavItemSelected(navItem, props)) {
      baseUrl = navItem.url.toLowerCase().split("#")[1];
      liClassNames.push(styles["ut-navigation__list__item--selected"]);
    }

    return (
      <li
        className={liClassNames.join(" ")}
        key={navItem.text}
        onClick={resetSessionStorageSearchData}
      >
        {navigationItem}
        {navItem.subMenuItems && (
          <NavSubMenuItem
            baseUrl={baseUrl}
            index={index}
            navbarItems={navItem.subMenuItems}
            isNavItemSelected={isNavItemSelected}
            {...props}
          />
        )}
      </li>
    );
  });

  return (
    <ul className={styles["ut-navigation__list"]}>{navigationMenuContent}</ul>
  );
};

NavMenuItems.propTypes = {
  expanededItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNavBarOpen: PropTypes.bool,
  navBarList: PropTypes.array,
  setExpandedItem: PropTypes.func,
  toggleNavbar: PropTypes.func,
};

export default NavMenuItems;
