import React from "react";
import PropTypes from "prop-types";
import _isEqual from "lodash.isequal";

import ArrowDown from "@mui/icons-material/ArrowDownward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";

import MenuItem from "../MenuItem/MenuItem";
import { trackUIEvent } from "../../../utils/tagManager";
import { sortAlphabetically } from "../../../utils/commonService";

import styles from "./SortComponent.scss";

class Sort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: [...props.sortOptions],
    };
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.sortOptions, this.props.sortOptions)) {
      this.setState({ sortOptions: [...this.props.sortOptions] });
      //Default sort order will apply only when no other sort option is applied.
      if (
        this.props.appliedSortIndex === "" ||
        this.props.appliedSortIndex === null ||
        this.props.appliedSortIndex === undefined
      ) {
        this.updateDefaultSortDetails();
      }
    }
  }

  componentWillUnmont() {
    this.props.clearState();
  }

  //Function is use to set default sort parameters for sort options.
  updateDefaultSortDetails() {
    let sortOptions = this.props.sortOptions;
    if (!sortOptions || !sortOptions.length) {
      return;
    }
    let sortIndex = sortOptions.findIndex((option) => option.isDefault);
    if (sortIndex >= 0) {
      this.props.updateSortDetails(
        sortOptions[sortIndex].value,
        sortIndex,
        this.getDefaultSortOrder(sortOptions[sortIndex].defaultOrder), //Default sort order for selected sort option.
      );
    }
  }

  //If default sort order is not set then default sort will be ascending.
  getDefaultSortOrder(sortOrder) {
    return sortOrder ? sortOrder : "ASC";
  }

  handleClick = (event) => {
    event.persist();
    this.props.handleClick(event);
  };

  handleClose = (event) => {
    event.persist();
    this.props.handleClose(event);
  };

  handleMenuClick = (option, index) => {
    this.props.handleClose(event);
    let sortOrder = this.getDefaultSortOrder(option.defaultOrder);
    if (this.props.appliedSortIndex === index) {
      sortOrder = this.props.sortOrder === "ASC" ? "DESC" : "ASC";
      this.props.updateSortOrder(sortOrder);
    } else this.props.updateSortDetails(option.value, index, sortOrder);

    trackUIEvent({
      category: "Sort",
      action: option.label,
      value: sortOrder,
    });
  };

  render() {
    const {
      anchorEl,
      anchorOrigin = {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin = {
        vertical: "top",
        horizontal: "center",
      },
    } = this.props;

    function getMenuItems(component) {
      let items = component.state.sortOptions.map((option, index) => {
        return (
          <div key={index}>
            <MenuItem
              index={index}
              onClick={() => component.handleMenuClick(option, index)}
              isSelected={component.props.appliedSortIndex === index}
            >
              <ListItemText primary={option.label} />
              {component.props.appliedSortIndex === index && (
                <div className={styles["ut-sort__icon"]}>
                  {component.props.sortOrder === "ASC" ? (
                    <ArrowUp />
                  ) : (
                    <ArrowDown />
                  )}
                </div>
              )}
            </MenuItem>
          </div>
        );
      });
      return items;
    }

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id="simple-menu"
        onClose={this.handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={transformOrigin}
      >
        {getMenuItems(this)}
      </Menu>
    );
  }
}

Sort.propTypes = {
  anchorEl: PropTypes.any,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["bottom", "center", "top"]),
    horizontal: PropTypes.oneOf(["center", "left", "right"]),
  }),
  appliedSortIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sortOrder: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      defaultOrder: PropTypes.string,
      isDefault: PropTypes.bool,
    }),
  ),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["bottom", "center", "top"]),
    horizontal: PropTypes.oneOf(["center", "left", "right"]),
  }),
};

export default Sort;
