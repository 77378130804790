import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BackButtonIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import CustomScrollBar from "../CustomScrollBar";
import MenuDrawer from "../MenuDrawer/MenuDrawer";

import { trackUIEvent } from "../../../utils/tagManager";

import styles from "./MultiDrawerComponent.scss";

const MultiDrawer = (props) => {
  const [anchorEl, setanchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const classNames = [];
  classNames.push(styles["ut-drawer"]);

  // if (props.isOpen) {
  props.drawers.length > 1 && classNames.push(styles["ut-drawer--open"]);
  // }

  const holderClassNames = [];
  holderClassNames.push(styles["ut-drawer__holder"]);

  // if (props.isOpen) {
  props.drawers.length > 1 &&
    holderClassNames.push(styles["ut-drawer__holder--active"]);

  useEffect(() => {
    if (props.drawers.length > 1)
      document.documentElement.classList.add("drawer-open");
    else document.documentElement.classList.remove("drawer-open");
  }, [props.drawers]);

  useEffect(() => {
    props.resetMultiDrawer();
  }, [props.currentActivePage]);

  const closeDrawer = (index, clickedLocation = "") => {
    if (props.drawers[index].canClose) {
      if (props.drawers[index].callback)
        props.drawers[index].callback(clickedLocation);
      else {
        trackUIEvent({
          category: "UI",
          action: "Close drawer",
          value: clickedLocation,
        });
        props.popDrawer();
      }
    }
  };

  const handleOutlineCick = (event) => {
    const drawerList = document.getElementsByClassName(styles["ut-drawer"]);
    if (event.target == drawerList[drawerList.length - 2]) {
      closeDrawer(drawerList.length - 2, "outside");
    }
  };

  const handleCloseIconClick = (event, index) => {
    closeDrawer(index, "closeIcon");
  };

  const renderActions = (actions) => {
    return actions.map((currentItem, index) => {
      const action = React.cloneElement(currentItem, {
        key: index,
      });
      return <>{action}</>;
    });
  };

  const headerClass = [styles["ut-drawer__header"]];
  if (
    props.isMyUser ||
    props.isAnonymousUser ||
    (!props.isAuthenticated && props.isPublicPage)
  ) {
    headerClass.push(styles["ut-drawer__header--public"]);
  }

  // Render extra actions of drawer component
  const renderExtraActions = (actions) => {
    return actions.map((currentItem, index) => {
      const action = React.cloneElement(currentItem.component, {
        onClick: () => {
          typeof currentItem.callback !== "undefined" &&
            currentItem.callback(event);
        },
        "data-tooltip": currentItem.tooltip,
      });
      return <span key={index}>{action}</span>;
    });
  };

  return <>
    <div
      className={holderClassNames.join(" ")}
      onClick={(event) => {
        handleOutlineCick(event);
      }}
    >
      {props.drawers.map((drawer, index) => {
        const className = [styles["ut-drawer"]];
        if (index <= props.drawers.length - 2 && drawer !== null)
          className.push(styles["ut-drawer--open"]);
        if (index < props.drawers.length - 2)
          className.push(styles["ut-drawer--background"]);
        return (
          <div key={index} className={className.join(" ")}>
            <div className={styles["ut-drawer__outline"]}>
              {drawer && index != props.drawers.length - 1 && (
                <>
                  <div className={headerClass.join(" ")}>
                    <IconButton className={styles["ut-drawer__header__close-btn"]} size="large">
                      {!drawer.closeIcon ? (
                        <>
                          <CloseIcon
                            className={styles["ut-drawer__header__cross"]}
                            onClick={(event) => {
                              handleCloseIconClick(event, index);
                              trackUIEvent({
                                category: "UI",
                                action: "Click",
                                value: "Close dialog modal",
                              });
                            }}
                          />
                          <BackButtonIcon
                            className={styles["ut-drawer__header__back"]}
                            onClick={(event) => {
                              handleCloseIconClick(event, index);
                              trackUIEvent({
                                category: "UI",
                                action: "Click",
                                value: "Back arrow button",
                              });;
                            }}
                          />
                        </>
                      ) : (
                        drawer.closeIcon
                      )}
                    </IconButton>

                    {drawer.title && <h4>{drawer.title}</h4>}

                    <div className={styles["ut-drawer__header__actions"]}>
                      {drawer.extraActions.length > 0 && (
                        <>
                          {drawer.extraActions &&
                            renderExtraActions(drawer.extraActions)}
                        </>
                      )}
                    </div>
                  </div>

                  <div className={styles["ut-drawer__cnt"]}>
                    <CustomScrollBar>
                      <div
                        className={styles["ut-drawer__cnt__main"]}
                        id="printDrawerContent"
                      >
                        {drawer.component}
                        {drawer.actions.length > 0 && (
                          <div
                            className={
                              styles["ut-drawer__cnt__main__actions"]
                            }
                          >
                            {renderActions(drawer.actions)}
                          </div>
                        )}
                      </div>
                    </CustomScrollBar>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles["ut-drawer__holder__actions"]}>
        {props.drawers.length > 1 &&
          props.drawers[props.drawers.length - 2].actions.length > 0 &&
          renderActions(props.drawers[props.drawers.length - 2].actions)}
      </div>
    </div>
  </>;
};

MultiDrawer.propTypes = {
  actions: PropTypes.array,
  extraActions: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default MultiDrawer;
