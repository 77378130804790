import { connect } from "react-redux";

import {
  sortHandleClick,
  sortHandleClose,
  updateSortOrder,
  updateSortDetails,
  clearSortComponentState,
} from "../../actions/SortActions";

import Sort from "./SortComponent";

const mapStateToProps = (state) => {
  return {
    anchorEl: state.sort.anchorEl,
    appliedSortIndex: state.sort.appliedSortIndex,
    sortOrder: state.sort.sortOrder,
    sortOptions: state.sort.sortOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClick: (event) => {
      dispatch(sortHandleClick(event));
    },
    handleClose: (event) => {
      dispatch(sortHandleClose(event));
    },
    updateSortOrder: (sortOrder) => {
      dispatch(updateSortOrder(sortOrder));
    },
    updateSortDetails: (value, appliedSortIndex, sortOrder) => {
      dispatch(updateSortDetails(value, appliedSortIndex, sortOrder));
    },
    clearState: () => {
      dispatch(clearSortComponentState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sort);
