import { connect } from "react-redux";

import Notification from "./NotificationComponent";
import { notificationActions } from "../../actions/NotificatioActions";
import { addOnNotificationTimeout } from "../../../constants/notificationConstants";

const mapStateToProps = (state) => {
  return {
    notifications: state.notification.notifications,
    onLine: state.app.onLine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeNotification: (notificationId) => {
      dispatch(notificationActions.removeNotification(notificationId));
      setTimeout(() => {
        dispatch(notificationActions.deleteNotification(notificationId));
      }, addOnNotificationTimeout);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
