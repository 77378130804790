import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

import styles from "./LinearProgressBarComponent.scss";

const classes = {
  colorPrimary: `LinearProgressBar-colorPrimary`,
  barColorPrimary: `LinearProgressBar-barColorPrimary`,
};

const Root = styled("div")({
  [`& .${classes.colorPrimary}`]: {
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: "#ffea99",
    },
  },
  [`& .${classes.barColorPrimary}`]: {
    "&.MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#ffcc00",
    },
  },
});

const LinearProgressBar = (props) => {
  const { isProgressBarLoading = false } = props;

  return (
    <Root>
      {isProgressBarLoading && (
        <div className={styles["ut-linear-progressbar"]}>
          <LinearProgress
            classes={{
              ...classes,
            }}
          />
        </div>
      )}
    </Root>
  );
};

LinearProgressBar.propTypes = {
  isProgressBarLoading: PropTypes.bool,
};

export default LinearProgressBar;
