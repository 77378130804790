import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import NavBar from "./NavBarComponent";

import {
  getNavigationList,
  setExpandedItem,
  toggleNavbar,
} from "../../actions/navBarActions";

const mapStateToProps = (state) => {
  return {
    navBarList: state.navBar.list,
    expanededItem: state.navBar.expanededItem,
    isNavBarOpen: state.navBar.isNavBarOpen,
    marketProfileID: state.app.marketProfileID,
    isMyUser: state.app.isMyUser,
    loggedInUserId:
      (state.app.loggedInUser && state.app.loggedInUser.iD) || null,
    isAccountSuspended: state.app.isAccountSuspended,
    showNavBar: state.navBar.showNavBar,
    windowSize: state.app.windowSize,
    isHidden: state.navBar.isHidden,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExpandedItem: (itemIndex, callback) => {
      dispatch(setExpandedItem(itemIndex, callback));
    },
    toggleNavbar: () => {
      dispatch(toggleNavbar());
    },
    getNavigationList: () => {
      dispatch(getNavigationList());
    },
    emptyNavbarState: () => {
      // we will use this in future if needed
    },
    abortNavigationRequest: () => {
      // we will use this in future if needed
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
