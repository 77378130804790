import React from "react";
import styles from "./NotificationComponent.scss";
import Notification from "./Notification";

const NotificationComponent = (props) => {
  const renderNotifications = () => {
    const { notifications } = props;
    const renderedNotifications = [];
    notifications.forEach((notification, id) => {
      renderedNotifications.push(
        <Notification
          id={id}
          isOnline={props.onLine}
          key={`notification-${id}`}
          removeNotification={props.removeNotification}
          {...notification}
        />,
      );
    });
    return renderedNotifications;
  };

  return (
    <div className={styles["ut-notification__outline"]}>
      {renderNotifications()}
    </div>
  );
};

export default NotificationComponent;
