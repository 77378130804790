import React from "react";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";

import { changeMarketPreference } from "../../../utils/commonService";
import { WINDOW_SIZES } from "../../../constants/configuration";

import styles from "./NavBarComponent.scss";

const NavSubMenuItem = (props) => {
  const subMenuItems = orderBy(props.navbarItems, "sortOrder", "asc");

  const handleMarketChange = (event, baseUrl, props) => {
    const marketId = event.target.dataset && event.target.dataset.marketid;
    if (props.marketProfileID !== Number(marketId)) {
      // Remove market dependent params
      if (baseUrl !== props.location.pathname) props.history.replace(baseUrl);

      changeMarketPreference(marketId);
    }
  };

  const navItemsContent = subMenuItems.map((subMenuItem) => {
    const subMenuClasses = [];
    subMenuClasses.push(styles["ut-navigation__list__item"]);
    if (props.isNavItemSelected(subMenuItem, props)) {
      subMenuClasses.push(styles["ut-navigation__list__item--selected"]);
    }

    return (
      <li className={subMenuClasses.join(" ")} key={subMenuItem.text}>
        {!subMenuItem.dataAttributes ? (
          <>
            <a href={subMenuItem.url}
              onClick={() => { props.windowSize === WINDOW_SIZES.isSmall && props.toggleNavbar() }}
            >{subMenuItem.text}</a>
            {/* props.approvalBadge */}
          </>
        ) : (
          <a
            className="marketProfileSwitcher"
            data-marketid={subMenuItem.dataAttributes["data-MarketID"]}
            onClick={(e) => {
              handleMarketChange(e, props.baseUrl, props);
            }}
          >
            {subMenuItem.text}
          </a>
        )}
      </li>
    );
  });

  const subNavClass = [styles["ut-navigation__submenu"]];
  if (props.index === props.expanededItem)
    subNavClass.push(styles["ut-navigation__submenu--active"]);

  return <ul className={subNavClass.join(" ")}>{navItemsContent}</ul>;
};

NavSubMenuItem.prototypes = {
  approvalBadge: PropTypes.node,
  baseUrl: PropTypes.string,
  index: PropTypes.number,
  navbarItems: PropTypes.object,
};

export default NavSubMenuItem;
